import { useEffect } from 'react';
import Mobile from './pages/Mobile/Mobile';
import Desktop from './pages/Desktop/Desktop';
import { postDefault, getKeyboard2, postMessage2 } from './api/axiosFunction';
import { useDispatch } from 'react-redux';
import {
  addClientInfo,
  addClientName,
  addIntent,
  IntentType,
} from './modules/defaults';
import { addBookMark, BookMarkObject } from './modules/bookMark';
import { addGreeting } from './modules/chatting';
import { getURLParams, responseParsing } from './api/functions';
import { converDesktop, convertMobile } from './modules/display';
import apis from './api/clientAPI';
import greetingState from './styles/greetingState';
import clientTitleName from './api/clientName';
import { answerGenerator } from './modules/thunks';

function App() {

  useEffect(() => {
    const client = clientName();
    const favicon = getFaviconEl();
    if (favicon) {
      favicon.setAttribute('href', `/favicon/${client}.png`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log(document.cookie)
    const client = clientName();
    defaultsData();
    keywordsData(client);
    // eslint-disable-next-line
  }, []);

  //redux Dispatch
  const dispatch = useDispatch();
  const addIntentBookMark = (
    intentes: IntentType,
    bookMark: BookMarkObject[]
  ) => {
    dispatch(addIntent(intentes));
    dispatch(addBookMark(bookMark));
  };

  const onConverMobile = () => {
    convertMobile();
  };
  const onConverDesktop = () => {
    converDesktop();
  };

  const defaultsData = async () => {
    const { data } = await postDefault();

    dispatch(
      addClientInfo(
        data.agent.name,
        data.agent.image,
        data.placeholder.response.LISTBOX,
        data.placeholder.response.INPUT
      )
    );
  };
  const keywordsData = async (client: string) => {
    const {
      data: { response, intents },
    } = await getKeyboard2();

    const responseData = responseParsing(response);
    const greeting = Object.keys(greetingState);
    const queryParams = getURLParams();

    if (!queryParams.keyword) {
      if (greeting.indexOf(client) > -1) {
        dispatch(addGreeting());
        setTimeout(() => {
          dispatch(answerGenerator(responseData));
        }, 6000);
      } else {
        dispatch(answerGenerator(responseData));
      }
    } else {
      const urlQueryRes = await postMessage2(
        decodeURI(queryParams.keyword),
        null,
        'text'
      );
      const responseData = responseParsing(urlQueryRes.data.response);
      dispatch(answerGenerator(responseData));
    }

    addIntentBookMark(intents, response.button.values);

    return responseData;
  };
  const clientName = () => {
    const { hostname, pathname } = window.location;
    const keys = Object.keys(apis);
    const values = Object.values(apis);
    const htmlTitle = document.querySelector('title') as HTMLTitleElement;
    let clientName = '';

    if (
      hostname.split('.')[0] === 'localhost' ||
      hostname.split('.')[0] === 'test-dualtalk' ||
      hostname.split('.')[0] === 'dev-vp-platform' ||
      hostname.split('.')[1] === 'localhost' ||
      hostname.split('.')[1] === 'test-dualtalk' ||
      hostname.split('.')[1] === 'dev-vp-platform' ||
      hostname === '192.168.1.209'
    ) {
      const valueIndex = values.indexOf(pathname.slice(1));
      const keysIndex = keys.indexOf(pathname.slice(1));
      if (valueIndex > -1) {
        const clientNameKey = Object.keys(clientTitleName);
        const clientNameValue = Object.values(clientTitleName);
        dispatch(addClientName(keys[valueIndex].split('-')[0]));
        clientName = keys[valueIndex].split('-')[0];
        const clientNameKeyIndex = clientNameKey.indexOf(clientName);
        const htmlTitleName = clientNameValue[clientNameKeyIndex];
        htmlTitle.innerHTML = htmlTitleName;
      } else if (keysIndex > -1) {
        const clientNameKey = Object.keys(clientTitleName);
        const clientNameValue = Object.values(clientTitleName);
        dispatch(addClientName(keys[keysIndex].split('-')[0]));
        clientName = keys[keysIndex].split('-')[0];
        const clientNameKeyIndex = clientNameKey.indexOf(clientName);
        const htmlTitleName = clientNameValue[clientNameKeyIndex];
        htmlTitle.innerHTML = htmlTitleName;
      } else {
        clientName = 'defaults';
        dispatch(addClientName('defaults'));
      }
    } else {
      const splitHostname = hostname.split('.');
      if (splitHostname[0] === 'www') {
        clientName = splitHostname[1];
        dispatch(addClientName(splitHostname[1]));
        const clientNameKey = Object.keys(clientTitleName);
        const clientNameValue = Object.values(clientTitleName);
        const clientNameKeyIndex = clientNameKey.indexOf(splitHostname[1]);
        const htmlTitleName = clientNameValue[clientNameKeyIndex];
        htmlTitle.innerHTML = htmlTitleName;
      } else {
        clientName = splitHostname[0];
        dispatch(addClientName(splitHostname[0]));
        const clientNameKey = Object.keys(clientTitleName);
        const clientNameValue = Object.values(clientTitleName);
        const clientNameKeyIndex = clientNameKey.indexOf(splitHostname[0]);
        const htmlTitleName = clientNameValue[clientNameKeyIndex];
        htmlTitle.innerHTML = htmlTitleName;
      }
    }
    return clientName;
  };

  onConverMobile();
  return <Mobile />;
}

const getFaviconEl = () => {
  return document.getElementById('favicon');
};

export default App;
