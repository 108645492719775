const kfosbiz = {
  title: 'assets/custom/kfowork/title.svg',
  wrap: {
    background: '#ffffff',
  },
  chattingBubbles: {
    resText: {
      background: '#F2F4FD',
      border: '1px solid #D6DBEE',
      color: '#000000',
    },
    action: {
      background: '#ffffff',
      border: '1.5px solid #093775',
      hoverColor: '#E0ECFF',
      color: '#093775',
    },
    relationButton: {
      background: {
        on: '#093775',
        off: '#ffffff',
      },
      border: '1.5px solid #093775',
      hover: {
        background: '#F2F4FD',
        color: '#093775',
      },
      active: {
        background: '#093775',
      },
      p: {
        color: {
          on: '#FFFFFF',
          off: '#093775',
        },
      },
    },
  },
  user: {
    reqText: {
      background: '#FF8C12;',
      color: '#000000',
    },
    reqTime: {
      color: '#111111',
    },
  },
  carousel: {
    ele: {
      border: {
        on: '1.5px solid #093775',
        off: '1px solid #D6DBEE',
      },
      background: {
        on: '#FFFFFF',
        off: '#ffffff',
      },
    },
    image: {
      background: '#ffffff',
    },
    swiper: {
      color: '#093775',
    },
    title: {
      color: '#093775',
      background: '#ffffff',
    },
    description: {
      color: '#383838',
    },
    button: {
      background: '#093775',
      color: '#ffffff',
    },
  },
  checkerboard: {
    wrap: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3,1fr)',
    },
    ele: {
      border: {
        on: '1.5px solid #093775',
        off: '1px solid #D6DBEE',
      },
      hover: '1.5px solid #093775',
      background: '#ffffff',
      display: 'flex',
      flexDirection: 'column',
    },
    text: {
      background: '#ffffff',
      color: '#093775',
      height: '24px',
    },
    imgEle: {
      height: '72px',
      background: '#ffffff',
    },
    img: {
      background: '#ffffff',
      objectFit: 'cover',
      maxWidth: '100%',
      maxHeight: '55px',
      borderRadius: '0',
    },
  },
  onecard: {
    wrap: {
      background: '#ffffff',
      border: '1px solid #D6DBEE',
    },
    button: {
      background: {
        on: '#093775',
        off: '#F2F4FD;',
      },
      border: {
        on: '#093775',
        off: '#D6DBEE',
      },
      font: {
        on: '#FFFFFF',
        off: '#093775',
      },
    },
    content: {
      color: '#383838',
    },
    image: {
      background: '#ffffff',
    },
  },
  relation: {
    button: {
      background: {
        on: '#093775',
        off: '#ffffff',
      },
      border: '1.5px solid #093775',
      hover: {
        background: '#F2F4FD',
        color: '#093775',
      },
      active: {
        background: '#093775',
        color: '#ffffff',
      },
      color: {
        on: '#ffffff',
        off: '#093775',
      },
    },
    listbutton: {
      background: '#ffffff',
      border: '1.5px solid #093775',
      hoverBackground: '#F2F4FD',
    },
    listboxtext: {
      color: '#000000',
      fillColor: '#0068B7',
      fontWeight: '500',
    },
    listele: {
      backgroundColor: {
        odd: '#ffffff',
        even: '#F4F4F4',
      },
      color: '#000000',
      hoverBackground: '#F6F7FE',
    },
    listmenu: {
      background: '#ffffff',
      border: '1px solid #E9EAEF',
    },
  },
  bookmark: {
    wrap: {
      background: '#ffffff',
    },
    button: {
      background: '#ffffff',
    },
    relation: {
      opacity: '1',
      color: '#111111',
    },
  },
  header: {
    wrap: {
      background: '#093775',
    },
    company: {
      background: 'rgba(0,0,0,0)',
    },
    title: {
      color: 'white',
    },
  },
  chatting: {
    wrap: {
      background: '#ffffff',
    },
  },
  client: {
    title: {
      color: '#111111',
    },
  },
  input: {
    inputWrap: {
      background: '#ffffff',
    },
    wrap: {
      background: '#ffffff',
    },
    postWrap: {
      border: {
        on: '1px solid #093775',
        off: '1px solid #D6DBEE',
      },
      background: {
        on: '#ffffff',
        off: '#F2F4FD',
      },
    },
    textInput: {
      background: {
        on: '#ffffff',
        off: '#F2F4FD',
      },
      color: {
        on: '#111111',
        off: '#111111',
      },
      placeholder: 'rgba(17, 17, 17, 0.2) !important',
    },
    sendbutton: {
      background: '#093775',
    },
  },
  visualpage: {
    header: {
      background: '#093775',
      color: '#FFFFFF',
    },
    wrap: {
      background: '#ffffff',
    },
    contentsWrap: {
      width: 'calc(100% - 25px)',
      marginLeft: '0',
    },
    footer: {
      color: '#C4C4C4',
    },
  },
  intent: {
    color: '#093775',
  },
};
export default kfosbiz;
