import OpenImage from '../../assets/bookmark/Open.png';
import styled from 'styled-components';
import { BookMarkObject, buttonState } from '../../modules/bookMark';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import { ChattingType, ClientNameProps, StyleThemeProps } from '../../types';
import { addRendingData } from '../../modules/chatting';

import { addUserBubbles } from '../../modules/userChatting';
import { getTime, responseParsing } from '../../api/functions';
import { postMessage2 } from '../../api/axiosFunction';
import { answerGenerator } from '../../modules/thunks';
import { useEffect, useState } from 'react';

const BookMark = () => {
  // redux state
  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );
  const { userChatting } = useSelector((state: RootState) => ({
    userChatting: state.userChatting,
  }));
  const data = useSelector((state: RootState) => ({
    bookMark: state.bookmark,
  }));
  const { buttonClickState } = useSelector((state: RootState) => ({
    buttonClickState: state.bookmark.buttonState,
  }));

  const dataArray: BookMarkObject[] = data.bookMark.data;
  const state: boolean = data.bookMark.bookmarkState;

  const [delay, setDelay] = useState(false);

  useEffect(() => {
    if (state) setTimeout(() => setDelay(true), 200);
    if (!state) setDelay(false);
  }, [state]);

  const dispatch = useDispatch();
  const onUserData = (message: string, list: ChattingType) => {
    dispatch(addUserBubbles(message));
    dispatch(addRendingData(list));
  };
  const onButtonState = () => dispatch(buttonState());

  const clickData = async (
    responseType: string,
    content: string,
    id: string | null
  ) => {
    onButtonState();

    if (content[0] === '<') {
      const newContent = content.split('>')[1].split('<')[0];
      onUserData(newContent, [
        { type: 'User', id: userChatting.length, time: getTime() },
      ]);
      const {
        data: { response },
      } = await postMessage2(newContent, id, 'button');
      const responseData = responseParsing(response);
      dispatch(answerGenerator(responseData));
    } else {
      onUserData(content, [
        { type: 'User', id: userChatting.length, time: getTime() },
      ]);
      const {
        data: { response },
      } = await postMessage2(content, id, 'button');
      const responseData = responseParsing(response);
      dispatch(answerGenerator(responseData));
    }
  };
  if (state && delay && dataArray.length !== 0) {
    return (
      <Wrap clientName={clientName} overflowState={buttonClickState}>
        <RelationWrap>
          {buttonClickState ? (
            dataArray.map((ele, index) => {
              return (
                <Relation
                  onClick={() => clickData(ele.type, ele.name, ele.id)}
                  key={index}
                  clientname={clientName}
                >
                  {ele.name}
                </Relation>
              );
            })
          ) : (
            <Relation clientname={clientName}>{dataArray[0].name}</Relation>
          )}
        </RelationWrap>
        <Button
          clientName={clientName}
          imageUrl={OpenImage}
          state={buttonClickState}
          onClick={onButtonState}
        />
      </Wrap>
    );
  } else {
    return <div></div>;
  }
};

export default BookMark;

interface WrapType {
  overflowState: boolean;
  clientName: string;
}

const Wrap = styled.div<WrapType>`
  width: 100%;
  height: ${(props) => (props.overflowState ? '140px' : '49px')};
  background: ${({ theme, clientName }) =>
    theme[clientName].bookmark.wrap.background};
  box-shadow: 0px -6px 10px rgba(1, 1, 1, 0.1);
  border-radius: 30px 30px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: all 0.3s linear;
`;
const RelationWrap = styled.div`
  width: 100%;
  margin-top: 3px;
  margin-left: 42px;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Relation = styled.div<StyleThemeProps>`
  width: 100%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  opacity: ${({ theme, clientname }) =>
    theme[clientname].bookmark?.relation?.opacity || '0.6'};
  margin-top: 10px;
  cursor: pointer;
  color: ${({ theme, clientname }) =>
    theme[clientname].bookmark?.relation?.color || '#111111'};
`;

interface ButtonProps {
  imageUrl: string;
  state: boolean;
  clientName: string;
}

const Button = styled.button<ButtonProps>`
  width: 24px;
  height: 24px;
  padding: 0px;
  border: 0px;
  margin-right: 30px;
  margin-top: 12px;
  background-color: ${({ theme, clientName }) =>
    theme[clientName].bookmark.button.background};
  background-image: url(${(props) => props.imageUrl});
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s linear;
  transform: ${(props) => (props.state ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
