import {AnyAction }from 'redux';

const RENDINGDATA = 'chatting/RendingData';
const ADDLOADING = 'chatting/AddLoading';
const SWITCHDATA = 'chatting/SwitchData';
const FINISHLOADING = 'chatting/FinishLoading';
const AUTOINTENT = 'chatting/AutoIntent';
const ADDGREETING = 'chatting/AddGreeting';

// Chatting을 추가하는 Function
const addRendingData = (list: ChattingType)=>({
  type:RENDINGDATA,
  list
});
// Client를 추가해놓고 Loading을 그 다음에 추가해놓는 Function
const addLoaing = (time:string)=>({
  type:ADDLOADING,
  time
})
// 로딩이 존재하는 곳에 데이터를 로딩을 지우고 원하는 데이터를 Switching 시키며
const switchData = (obj:ChattingObject,index:number)=>({
  type:SWITCHDATA,
  obj,
  index
})

const finishLoading = (obj:ChattingObject,index:number)=>({
  type:FINISHLOADING,
  obj,
  index
})
const autoIntent = (obj:ChattingObject,index:number)=>({
  type:AUTOINTENT,
  obj,
  index
})
const addGreeting = ()=>({
  type:ADDGREETING
})

const initialState:ChattingState = [];

const chatting = (
  state:ChattingState=initialState,
  action:AnyAction
)=>{
  let newChattingData = state.slice()
  switch(action.type){
    //
    case RENDINGDATA:
      newChattingData.push(action.list)
      return newChattingData;
    case ADDLOADING:
      newChattingData.push([
        {
          type:'Client',
          id:0,
          time:action.time
        },
        {
          type:'Loading',
          id:0,
          time:action.time
        }
      ])
      return newChattingData
    case SWITCHDATA:
      newChattingData[action.index]=[
        ...newChattingData[action.index].slice(0,-1),
        action.obj,
        {
          type:'Loading',
          id:0,
          time:action.time
        }
      ]
      return newChattingData;
    case FINISHLOADING:
      newChattingData[action.index]=[
        ...newChattingData[action.index].slice(0,-1),
        action.obj
      ]
      return newChattingData;
    case AUTOINTENT:
      newChattingData[action.index]=[
        ...newChattingData[action.index].slice(0,-1),
        action.obj,
        {
          type:'null',
          id:0,
          time:0
        }
      ]
      return newChattingData;
    case ADDGREETING:
      newChattingData.push([{
        type:'Greeting',
        id:0,
        time:''
      }])
      return newChattingData
    default:
      return state
  }
};

type ChatType = 'ChatBubbles' 
| 'Relation' 
| 'CheckerBoard' 
| 'Carousel' 
| 'OneCard' 
| 'Action'
| 'User'
| 'Client'
| 'Loading'
| 'AutoIntents'
| 'Greeting'
| 'VisualPage'
| 'null'
// ChatObject에서 time은 chat을 더할때마다 컨트롤한다.

type ChattingObject = {
  type:ChatType; //어떤 타입의 chatting을 의미하는지
  id: number; // type의 몇번째 chatting을 의미하는지
  time: string; //채팅이 만들어진 시점을 저장
};
// 하나의 Chatting을 Array로 묶어놓는다. 이 Array는 한번의 유저의 요청 그리고 요청에 대한 Response가 담겨있다.
type ChattingType = ChattingObject[]; 
// 모든 대화를 저장한다.
type ChattingState = ChattingType[];

export {
  addRendingData,
  addLoaing,
  switchData,
  finishLoading,
  autoIntent,
  addGreeting
};

export type {
  ChatType,
  ChattingObject,
  ChattingType,
  ChattingState
}

export default chatting;