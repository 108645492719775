const ADDSEARCHINTENT = 'intent/AddSearchIntent';
const AUTOINTENTSTATE = 'intent/AutoIntentState';

const addSearchIntent = (list: IntentType) => ({
  type: ADDSEARCHINTENT,
  list,
});

const autoIntentState = (state: boolean) => ({
  type: AUTOINTENTSTATE,
  state,
});

const initailState: IntentType = {
  searchIntent: [],
  autoIntentState: false,
};

const intent = (state: IntentType = initailState, action: any) => {
  // console.log(action);
  // console.log(state);
  switch (action.type) {
    case ADDSEARCHINTENT:
      return {
        ...state,
        searchIntent: [...action.list],
      };
    case AUTOINTENTSTATE:
      return {
        ...state,
        autoIntentState: action.state,
      };

    default:
      return state;
  }
};

// type Actions = ReturnType<typeof addSearchIntent>;
type IntentType = any;

export type { IntentType };

export default intent;
export { addSearchIntent, autoIntentState };
