// import React, {useState,useEffect} from "react";
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Header from '../../components/Mobile/Haeder';
import Input from '../../components/Mobile/Input';
import Chatting from '../../components/Mobile/Chatting';
import Modal from '../../components/Mobile/Modal';
import { RootState } from '../../modules';
import { ClientNameProps } from '../../types';

const Mobile = () => {
  // const [height, setHeight] = useState(window.innerHeight);

  // useEffect(() => {
  //   const mobileResponseive = () => {
  //     setHeight(100);
  //   };
  //   window.addEventListener('resize', mobileResponseive);
  //   return () => {
  //     window.removeEventListener('resize', mobileResponseive);
  //   };
  // }, [height]);

  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );

  return (
    <TotalWrap>
      <EmptyWrapLeft></EmptyWrapLeft>
      <Wrap clientname={clientName}>
        <Header />
        <Chatting />
        <Input />
      </Wrap>
      <EmptyWrapRight></EmptyWrapRight>
      <Modal></Modal>
    </TotalWrap>
  );
};

export default Mobile;
interface WrapProps {
  clientname: string;
}
const TotalWrap = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
`;
const Wrap = styled.div<WrapProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 375px;
  width: 375px;
  max-width: 375px;
  height: 100%;
  background-color: #fff;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
`;

const EmptyWrapLeft = styled.div`
  width: 100%;
  background-color: #333333;
  height: 100%;
  z-index: 9999999999999;
`;
const EmptyWrapRight = styled.div`
  width: 100%;
  background-color: #333333;
  height: 100%;
  z-index: 9999999999999;
`;
