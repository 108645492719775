import axios from 'axios';
import apis from './clientAPI';
import { v4 as uuid } from 'uuid';

//dualtalk

const switchParameter = () => {
  const { hostname, pathname } = window.location;
  const keys = Object.keys(apis);
  const values = Object.values(apis);
  if (
    hostname.split('.')[0] === 'localhost' ||
    hostname.split('.')[0] === 'test-dualtalk' ||
    hostname.split('.')[0] === 'dev-vp-platform' ||
    hostname.split('.')[1] === 'localhost' ||
    hostname.split('.')[1] === 'test-dualtalk' ||
    hostname.split('.')[1] === 'dev-vp-platform' ||
    hostname === '192.168.1.138'
  ) {
    const valueIndex = values.indexOf(pathname.slice(1));
    const keysIndex = keys.indexOf(pathname.slice(1));
    if (valueIndex > -1) {
      const reloadURL = window.location.origin + '/' + keys[valueIndex];
      window.location.replace(reloadURL);
    } else if (keysIndex > -1) {
      return `http://192.168.0.11:8001/chats/v_0_0_1/platforms/xinapse/${values[keysIndex]}`;
    } else {
      return 'http://192.168.0.11:8001/chats/v_0_0_1/platforms/xinapse/sUYAa5ml5jA3nFIhmDg5VZfR2HrZMkBw';
    }
  } else {
    const splitHostname = hostname.split('.');
    if (splitHostname[0] === 'www') {
      const keysIndex = keys.indexOf(splitHostname[1]);
      return `https://api.xinapse.co.kr/chats/v_0_0_1/platforms/xinapse/${values[keysIndex]}`;
    } else {
      const keysIndex = keys.indexOf(splitHostname[0]);
      return `https://api.xinapse.co.kr/chats/v_0_0_1/platforms/xinapse/${values[keysIndex]}`;
    }
  }
};

axios.defaults.baseURL = `${switchParameter()}`;
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const API_KEY = {
  xinapse_test: 'dc7cb66d840232fb09862c2a762e0d44',
};
const key = setCookieUUID();

function postDefault() {
  //유저키 발급은 어떻게?
  return axios.post('defaults', {
    user_key: key,
  });
}

function getKeyboard2() {
  return axios.get(
    `/keyboard2?user_key=${key}&&type=buttons&platform=vp&vp_test=false`
  );
}

function postMessage2(content: string, id: string | null, type: string) {
  if (id) {
    return axios.post('/message2', {
      content,
      control: { intent: { id: id } },
      platform: 'vp',
      type,
      user_key: key,
      vp_test: false,
    });
  }
  return axios.post('/message2', {
    content,
    platform: 'vp',
    type,
    user_key: key,
    vp_test: false,
  });
}

function setCookieUUID() {
  // document.cookie = 'uuid=; expires=Thu, 18 Dec 1999 12:00:00 GMT';
  if (!document.cookie.match('uuid')) {
    let date = new Date();
    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
    document.cookie =
      'uuid=guest-' + uuid() + ';expire=' + date.toUTCString() + ';path=/';
  }
  const cookie = document.cookie.split(';');
  const uuidCookie = cookie.filter((ele) => ele.indexOf('uuid') > -1)[0];
  return uuidCookie;
}

export { API_KEY, postDefault, getKeyboard2, postMessage2, setCookieUUID };

// function domain () {
//   let apiUrl =  'http://dev-api.xinapse.ai/chats/v_0_0_1/platforms/xinapse/E0jXZJqUkjJHKUBqjl9cAbcAo43H3G1a'
//   const curUrl = window.location.href;
//   const ipRegex = /^(http:\/\/)(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?):([0-9]{2,5})/g
//   if (curUrl.indexOf('localhost') > -1 || curUrl.indexOf('dev-') > -1 || ipRegex.test(curUrl)) {
//     axios.defaults.baseURL = "http://dev-api.xinapse.ai"
//   // Test
//   } else if (curUrl.indexOf('test-') > -1) {
//     axios.defaults.baseURL = "http://test-api.xinapse.ai"
//   // Staging
//   } else if (curUrl.indexOf('staging-') > -1) {
//     axios.defaults.baseURL = "http://staging-api.xinapse.ai"
//   }
//   return axios.defaults.baseURL;
// }
