import { combineReducers } from 'redux';
import bookmark from './bookMark';
import carousel from './carousel';
import chatbubbles from './chatBubbles';
import chatting from './chatting';
import checkerBoard from './checkerBoard';
import defaults from './defaults';
import userChatting from './userChatting';
import relation from './relation';
import onecard from './oneCard';
import visualPage from './visualPage';
import display from './display';
import temporary from './temporary';
import intent from './intent';
import modal from './modal';

const rootReducer = combineReducers({
  bookmark,
  carousel,
  chatbubbles,
  chatting,
  checkerBoard,
  defaults,
  userChatting,
  relation,
  onecard,
  visualPage,
  display,
  temporary,
  intent,
  modal,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
