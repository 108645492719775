import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../modules';
import { IntentObject } from '../../modules/defaults';
import { addSearchIntent, IntentType } from '../../modules/intent';
import { NotoSans14 } from '../../styles/fontStyls';
import { ClientNameProps } from '../../types';

interface IntentProps {
  input: string;
  setInput: (str: string) => void;
  postInputUpdate: (input: string, id: string) => void;
}

const chagneTextStyleBold = (name: string, input: string) => {
  if (input.length !== 0) {
    const filterName: string = name;
    const arrFilterName: (string | JSX.Element)[] = filterName.split('');
    const arrInput = input.split(' ');

    const styledText = arrInput.map((word) => {
      const filterIndex = filterName.toUpperCase().indexOf(word.toUpperCase());
      let num = 0;
      if (filterIndex > -1 && num === 0) {
        for (let i = filterIndex; i < filterIndex + word.length; i++) {
          arrFilterName[i] = <b>{arrFilterName[i]}</b>;
        }
        num++;
      }

      return arrFilterName;
    });
    return styledText[0];
  }
};

const Intent = ({ input, setInput, postInputUpdate }: IntentProps) => {
  const dispatch = useDispatch();
  const searchIntentList = (list: IntentType) => {
    dispatch(addSearchIntent(list));
  };

  const { intents } = useSelector((state: RootState) => ({
    intents: state.defaults.intent,
  }));
  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );
  const { intentSearch } = useSelector((state: RootState) => ({
    intentSearch: state.intent,
  }));

  useEffect(() => {
    if (!input || !input.trim()) return searchIntentList([]);

    const arrInput = input.split(' ');
    const filterInput = arrInput.filter(
      (word, index) => word !== ' ' && arrInput.indexOf(word) === index
    );

    const searchWordss: IntentType = filterInput.map((word, index: number) => {
      let searchWords = [];

      searchWords = intents.filter((ele: IntentObject) => {
        const intent = ele.name.toUpperCase();
        return index === 0 && intent.indexOf(word.toUpperCase()) > -1;
      });

      if (index > 0) {
        searchWords = searchWords.filter(
          (search: { name: string }) => search.name.indexOf(word) > -1
        );
      }

      return searchWords;
    });

    searchIntentList(searchWordss[0].slice(0, 4));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, intents]);

  const handleOnClick = (ele: { name: string; id: string }) => {
    setInput('');
    postInputUpdate(ele.name, ele.id);
  };

  return (
    <Wrap state={intentSearch.searchIntent.length !== 0}>
      {intentSearch.searchIntent.map(
        (ele: { id: any; selected?: any; name: any }) => {
          return (
            <IntentEleWrap
              key={ele.id}
              clientname={clientName}
              onClick={() => handleOnClick(ele)}
              selected={ele?.selected}
            >
              {chagneTextStyleBold(ele.name, input)}
            </IntentEleWrap>
          );
        }
      )}
    </Wrap>
  );
};

export default Intent;

interface WrapProps {
  state: boolean;
}
interface IntentWrap {
  clientname: string;
  selected: boolean;
}

const Wrap = styled.div<WrapProps>`
  display: ${(props) => (props.state ? '' : 'none')};
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 375px;
  height: ${(props) => (props.state ? 'calc(100% - 72px)' : '0px')};
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 1000;
`;

const IntentEleWrap = styled.div<IntentWrap>`
  border-radius: 20px 20px 0 20px;
  padding: 11px 20px 13px 12px;
  margin-bottom: 12px;
  margin-right: 18px;
  background-color: ${(props) => (props.selected ? '#f4f4f4' : '#fff')};
  color: #898e92;
  ${NotoSans14}
  b {
    color: ${({ theme, clientname }) => theme[clientname].intent.color};
  }
  :hover {
    background-color: #c9c9c9;
    cursor: pointer;
  }
`;
