import { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Lan } from '../../assets/header/Lan.svg';
import { ReactComponent as Menu } from '../../assets/header/Menu.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../modules';
import ToastPopup from './ToastPopup';
import { NotoSans17 } from '../../styles/fontStyls';
import MenuComponent from './Menu';
import { ClientNameProps, StyleThemeProps } from '../../types';

const Header = () => {
  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );
  const { header } = useSelector((state: RootState) => ({
    header: state.defaults.name,
  }));
  const [errorState, setErrorState] = useState(false);
  const [menuState, setMenuState] = useState(false);
  return (
    <HeaderWrap clientname={clientName}>
      {menuState ? (
        <MenuComponent
          state={menuState}
          setState={setMenuState}
        ></MenuComponent>
      ) : (
        ''
      )}
      {clientName === 'defaults' ? (
        <MenuBtn
          onClick={() => {
            setMenuState(true);
          }}
        />
      ) : (
        <div />
      )}

      <CompanyWrap
        clientname={clientName}
        onClick={() => {
          window.location.href = `${window.location.origin}${window.location.pathname}`;
        }}
      >
        <HomeImg
          src={`https://chatbot-front.s3.ap-northeast-2.amazonaws.com/${clientName}/header/Title.png`}
          clientname={clientName}
        />
        <Title clientname={clientName}>{header}</Title>
      </CompanyWrap>
      {clientName === 'defaults' ? (
        <LanBtn
          onClick={() => {
            setErrorState(true);
            setTimeout(() => {
              setErrorState(false);
            }, 1000);
          }}
        />
      ) : (
        <div />
      )}

      {errorState ? <ToastPopup /> : ''}
    </HeaderWrap>
  );
};

export default Header;

const HeaderWrap = styled.div<StyleThemeProps>`
  width: 375px;
  height: 58px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 6px 4px 0 rgb(46 47 48 / 4%);
  background: ${({ theme, clientname }) =>
    theme[clientname].header.wrap.background};
  z-index: 1000;
  position: fixed;
  top: 0;
`;
const CompanyWrap = styled.button<StyleThemeProps>`
  height: 25px;
  display: flex;
  align-items: center;
  border: 0px;
  background: ${({ theme, clientname }) =>
    theme[clientname].header.company.background};
`;
const MenuBtn = styled(Menu)`
  margin-left: 18px;
`;
const LanBtn = styled(Lan)`
  margin-right: 18px;
`;
const Title = styled.div<StyleThemeProps>`
  ${NotoSans17}
  color:${({ theme, clientname }) => theme[clientname].header.title.color};
  margin-left: 12px;
`;
const HomeImg = styled.img<StyleThemeProps>`
  path {
    fill: ${({ theme, clientname }) => theme[clientname].header.title.color};
  }
`;
const LogoImg = styled.img<StyleThemeProps>`
  width: 142.63px;
`;
