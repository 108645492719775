import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../modules";

const Greeting = ()=>{
  const {clientName} = useSelector((state:RootState)=>({
    clientName:state.defaults.clientName
  }))
  return (
    <Wrap>
      <video autoPlay muted>
        <source src={`https://chatbot-front.s3.ap-northeast-2.amazonaws.com/${clientName}/greeting/Greeting.mp4`} type='video/mp4'/>
      </video>
    </Wrap>
  )
}

export default Greeting;

const Wrap = styled.div`
  width: 100%;
  height: calc(100vh - 82px - 58px);
  display: flex;
  align-items: center;
  justify-content: center;
  video{
    width: 80%;
    height: 80%;
  }
`