// eslint-disable-next-line import/no-anonymous-default-export
export default {
  kfo: 'M5IttTXXff-NBsSwhyZLM1rD4uOtfEmL',
  'kfo-test': '-BgqnfT6YPVeB0bggtBYTFhCfvUyRWDu',
  kfowork: 'a05pkh7QBMwhKElvtjmvHk1d3JDul9ah',
  'kfowork-test': '__HKkdY0MLM70mF_WMBdJDsNL3XKfBr3',
  kfoedu: 'PVgCs01-Ny83joo0z64ZIwPL1ij-eDEh',
  'kfoedu-test': 'G5Z8xaWyguIau0SK5FXCioeuII3aqhPj',
  // 'kfo-sbiz': 'VZ3J4-mmW7kJ_v5VHRvwzzzV4I6NKkX7',
  'kfo-sbiz': 'VZ3J4-mmW7kJ_v5VHRvwzzzV4I6NKkX7',
};
