import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import {
  CarouselList,
  CarouselObject,
  ClickDataFuction,
  ClientNameProps,
  StyleThemeProps,
} from '../../../types';
import { NotoSans14, NotoSans16 } from '../../../styles/fontStyls';
import { useSelector } from 'react-redux';
import { RootState } from '../../../modules';
import { useState } from 'react';

interface CarouselProps {
  index: number;
  list: CarouselList;
  clickData: ClickDataFuction;
}
interface CarouselEleProps {
  ele: CarouselObject;
  clickData: ClickDataFuction;
}
SwiperCore.use([Navigation]);
const CarouselEle = ({ ele, clickData }: CarouselEleProps) => {
  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );
  const [state, setState] = useState(false);
  return (
    <CarouselEleWrap state={state} clientname={clientName}>
      <CarouselContent>
        <CarouselImg src={ele.image} clientname={clientName} />
        <CarouselTitle
          clientname={clientName}
          dangerouslySetInnerHTML={{ __html: ele.title }}
        ></CarouselTitle>
        <CarouselDescription
          clientname={clientName}
          dangerouslySetInnerHTML={{ __html: ele.content }}
        ></CarouselDescription>
      </CarouselContent>
      <CarouselButton
        clientname={clientName}
        onClick={() => {
          clickData('User', 'button', ele.title, ele.id);
          setState(true);
        }}
      >
        {ele.buttonName}
      </CarouselButton>
    </CarouselEleWrap>
  );
};

const Carousel = ({ index, list, clickData }: CarouselProps) => {
  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );
  return (
    <CarouselWrap>
      <CarouselMoving clientname={clientName}>
        <Swiper
          autoHeight={true}
          spaceBetween={12}
          freeMode={true}
          navigation={
            clientName === 'kfo' ||
            clientName === 'kfowork' ||
            clientName === 'kfoedu' ||
            clientName === 'kfo-sbiz'
              ? true
              : false
          }
          breakpoints={{
            0: {
              width: 166,
              slidesPerView: 1,
            },
            332: {
              width: 332,
              slidesPerView: 2,
            },
          }}
        >
          {list.map((ele, idx) => {
            return (
              <SwiperSlide key={idx}>
                <CarouselEle ele={ele} clickData={clickData} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </CarouselMoving>
    </CarouselWrap>
  );
};

export default Carousel;
interface CarouselEleWrapProps {
  state: boolean;
  clientname: string;
}

const CarouselWrap = styled.div`
  display: flex;
  width: calc(100%);
  margin-top: 12px;
`;

const CarouselMoving = styled.div<StyleThemeProps>`
  height: 330px;
  width: 100%;
  overflow: visible;
  .swiper-container {
    padding-left: 18px;
    overflow: flow;
    height: 330px;
  }

  .swiper-container > .swiper-button-prev {
    left: 5px;
    top: 70px;
    color: ${({ theme, clientname }) =>
      theme[clientname].carousel?.swiper?.color || 'gray'};
  }
  .swiper-container > .swiper-button-next {
    right: 5px;
    top: 70px;
    color: ${({ theme, clientname }) =>
      theme[clientname].carousel?.swiper?.color || 'gray'};
  }

  .swiper-container > .swiper-button-prev.swiper-button-disabled,
  .swiper-container > .swiper-button-next.swiper-button-disabled {
    opacity: 0;
  }
  .swiper-container > .swiper-button-prev::after,
  .swiper-container > .swiper-button-next::after {
    font-size: 28px;
  }
`;

const CarouselEleWrap = styled.div<CarouselEleWrapProps>`
  width: 148px;
  height: 317px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: ${({ theme, clientname, state }) =>
    state
      ? theme[clientname].carousel.ele.border.on
      : theme[clientname].carousel.ele.border.off};
  box-sizing: border-box;
  box-shadow: 4px 6px 14px rgba(57, 57, 57, 0.2);
  border-radius: 24px;
  background-color: ${({ theme, clientname, state }) =>
    state
      ? theme[clientname].carousel?.ele?.background.on
      : theme[clientname].carousel?.ele?.background.off};
`;

const CarouselContent = styled.div`
  width: 100%;
  height: calc(100% - 46px);
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  border-radius: 24px 24px 0px 0px;
`;
const CarouselImg = styled.img<StyleThemeProps>`
  background-color: ${({ theme, clientname }) =>
    theme[clientname].carousel?.image?.background || 'gray'};
  width: 100%;
  height: 130px;
  user-select: none;
  object-fit: cover;
  border: 0px;
`;

const CarouselTitle = styled.div<StyleThemeProps>`
  width: calc(100% - 24px);
  margin-left: 12px;
  margin-right: 12px;
  ${NotoSans16}
  font-weight: 500;
  margin-top: 12px;
  color: ${({ theme, clientname }) => theme[clientname].carousel.title.color};
  p {
    ${NotoSans16}
    font-weight: bold;
    /* identical to box height, or 125% */
    color: ${({ theme, clientname }) => theme[clientname].carousel.title.color};
    margin-top: 0px;
    user-select: none;
  }
`;

const CarouselDescription = styled.div<StyleThemeProps>`
  width: calc(100% - 24px);
  margin-top: 6px;
  margin-left: 12px;
  margin-right: 12px;
  ${NotoSans14}
  p {
    ${NotoSans14}
    color: ${({ theme, clientname }) =>
      theme[clientname].carousel.description.color};
    margin: 0px;
    user-select: none;
  }
`;

const CarouselButton = styled.button<StyleThemeProps>`
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 12px;
  width: 124px;
  height: 34px;
  background: ${({ theme, clientname }) =>
    theme[clientname].carousel.button.background};
  border-radius: 18px;
  border: 0px;
  color: white;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  color: ${({ theme, clientname }) => theme[clientname].carousel.button.color};
`;
